import { globalContext } from 'context';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { UserDto } from 'types/api/user.dto';
import { getResource } from 'utils/api';

export const Shell: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    dispatch,
    state: { isAuthenticated },
  } = useContext(globalContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    setLoading(true);

    const getUser = async () => {
      try {
        const user = await getResource<UserDto>('/users/me', true);
        dispatch({ type: 'set-user', payload: user });
      } catch (e) {
        dispatch({ type: 'logout' });
      }

      setLoading(false);
    };

    getUser();
  }, [isAuthenticated, dispatch]);

  return <>{loading ? null : children}</>;
};
