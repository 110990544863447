import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { OAuthButtonGroup } from 'components/ui/OAuthButtons';
import { PasswordField } from 'components/ui/PasswordField';
import { globalContext } from 'context';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { HiUserAdd } from 'react-icons/hi';
import { getAuthToken } from 'utils/api';

export const LoginPage = () => {
  const navigate = useNavigate();

  const {
    state: { isAuthenticated },
  } = useContext(globalContext);

  const toast = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    document.title = 'MapIQ - Login to Start Your Challenge';
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/auth-redirect');
    }
  }, [isAuthenticated, navigate]);

  const login = async () => {
    if (!email || !password) return;

    setLoggingIn(true);

    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);

    try {
      const r = await getAuthToken(email, password);
      Cookies.set('token', r.access_token);
      document.location.href = '/auth-redirect';
    } catch (e) {
      toast({
        title: 'Login failed',
        description: (e as Error).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }

    setLoggingIn(false);
  };

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={false}
          buttons={[
            {
              label: 'Sign Up',
              onClick: () => navigate('/register'),
              bgColor: 'green.300',
              icon: <Icon h={5} w={5} as={HiUserAdd} />,
            },
          ]}
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: '12' }} px={{ base: '0', sm: '8' }}>
          <Stack spacing="8">
            <Stack spacing="6">
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size={{ base: 'xs', md: 'sm', lg: 'lg' }}>Log in to your account</Heading>
              </Stack>
            </Stack>
            <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={{ base: 'transparent', sm: 'white' }}
              boxShadow={{ base: 'none', sm: 'md' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
            >
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </FormControl>
                  <PasswordField
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    onKeyDown={(e) => e.key === 'Enter' && login()}
                  />
                </Stack>
                <HStack justify="flex-end">
                  <Button variant="text" size="sm" onClick={() => navigate('/forgot-password')}>
                    Forgot password?
                  </Button>
                </HStack>
                <Stack spacing="6">
                  <Button
                    bg="blue.500"
                    color="white"
                    onClick={login}
                    isLoading={loggingIn}
                    type="submit"
                  >
                    Sign in
                  </Button>
                  <HStack>
                    <Divider />
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                      or continue with
                    </Text>
                    <Divider />
                  </HStack>
                  <OAuthButtonGroup />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};
