import { Layout } from 'components/layout/Layout';
import { globalContext } from 'context';
import { GameController } from 'modules/Game/GameController';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateGameDto, FinishGameDto, GameDto, GameMode } from 'types/api/game.dto';
import { sendRequest } from 'utils/api';

export const GamePage = () => {
  const {
    state: { user },
  } = useContext(globalContext);

  const hasRunOnce = useRef(false);

  const { mode } = useParams<{ mode: GameMode }>() as { mode: GameMode };

  const [game, setGame] = useState<GameDto>();

  useEffect(() => {
    document.title = `MapIQ - Play ${mode} challenge`;
  }, []);

  const createGame = async () => {
    const newGame = await sendRequest<CreateGameDto, GameDto>(
      '/games/start',
      'POST',
      { started: new Date().toISOString(), mode },
      true
    );

    return newGame;
  };

  const finishGame = async (id: string, dto: FinishGameDto): Promise<void> => {
    const game = await sendRequest<FinishGameDto, GameDto>(
      `/games/finish/${id}`,
      'PATCH',
      dto,
      false
    );
    setGame(game);
  };

  useEffect(() => {
    if (hasRunOnce.current || game) return;

    createGame().then((g) => g && setGame(g));

    hasRunOnce.current = true;
  }, []);

  return (
    <Layout showFooter={false} showNavbar={false}>
      <GameController
        user={user}
        gameInitial={game}
        onGameEnded={(id, dto) => finishGame(id, dto)}
      />
    </Layout>
  );
};
