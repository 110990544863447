import { Box, useBoolean } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { UserDataModal } from 'components/ui/modals/UserDataModal';
import { globalContext } from 'context';
import { useContext, useEffect } from 'react';

export const AuthRedirectPage = () => {
  const navigate = useNavigate();

  const {
    dispatch,
    state: { user },
  } = useContext(globalContext);

  const [userDataModal, setUserDataModal] = useBoolean();

  useEffect(() => {
    if (user) {
      if (user.nickname) {
        navigate('/hub');
      } else {
        setUserDataModal.on();
      }
    }
  }, [navigate, setUserDataModal, dispatch, user]);

  return (
    <Layout navbar={<Navbar showUserMenu={false} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        {user && <UserDataModal open={userDataModal} userId={user.id} />}
      </Box>
    </Layout>
  );
};
