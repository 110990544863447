import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { globalContext } from 'context';
import { GameModeOptionCopyDto } from 'types/api/utils.dto';
import { useDataResource } from 'utils/api';

import airports from 'assets/airports.png';
import capitals from 'assets/capitals.png';
import classic from 'assets/classic.png';
import rapid from 'assets/rapid.png';
import short from 'assets/short.jpg';
import stadiums from 'assets/stadiums.png';
import unesco from 'assets/unesco.png';

const modeImages = {
  classic: classic,
  short: short,
  rapid: rapid,
  stadiums: stadiums,
  unesco: unesco,
  airports: airports,
  capitals: capitals,
};

export const HubPage = () => {
  const navigate = useNavigate();

  const { state } = useContext(globalContext);

  const { data, isLoading } = useDataResource<GameModeOptionCopyDto[]>(
    '/utils/game-modes-copy',
    false
  );

  useEffect(() => {
    document.title = 'MapIQ Hub - Select a Challenge Mode';
  }, []);

  return (
    <Layout navbar={<Navbar showUserMenu={state.isAuthenticated} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        {isLoading ? (
          <Container centerContent h="full" alignItems="center" justifyContent="center">
            <Spinner size="xl" />
          </Container>
        ) : (
          <>
            <Container maxW="lg" py={{ base: 4 }}>
              <Heading as="h1" size="xl" fontWeight={900} textAlign="center">
                Game Hub
              </Heading>
            </Container>

            <SimpleGrid
              p={[8, 12]}
              spacing={8}
              columns={[1, 1, 1, 1, 2]}
              alignItems="center"
              gridAutoRows="1fr"
            >
              {data?.map((mode: GameModeOptionCopyDto, i: number) => (
                <Card
                  direction={{ base: 'column', lg: 'row' }}
                  overflow="hidden"
                  variant="outline"
                  key={i}
                >
                  <Image
                    objectFit="cover"
                    src={modeImages[mode.key]}
                    alt="Map with pin"
                    maxW={{ base: 'full', lg: '250px' }}
                    maxH={{ base: 'full' }}
                  />

                  <Stack>
                    <CardBody>
                      <Heading size="md">{mode.label}</Heading>

                      <Text py="2" h={{ base: '140px', md: '100px', xl: '120px' }}>
                        {mode.description}
                      </Text>
                    </CardBody>

                    <CardFooter>
                      <Button
                        variant="solid"
                        bgColor="green.500"
                        color="white"
                        onClick={() => navigate(`/play/${mode.key}`)}
                      >
                        Play
                      </Button>
                    </CardFooter>
                  </Stack>
                </Card>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    </Layout>
  );
};
