import {
  Box,
  Flex,
  Heading,
  Hide,
  Icon,
  Select,
  Show,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { IoMdPin } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { createColumnHelper } from '@tanstack/react-table';
import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { useEffect, useState } from 'react';
import { DataTable } from 'react-chakra-ui-table-v2';
import { GameMode } from 'types/api/game.dto';
import { LeaderboardRowDto } from 'types/api/leaderboard.dto';
import { GameModeOptionCopyDto } from 'types/api/utils.dto';
import { useDataResource } from 'utils/api';

const columnHelper = createColumnHelper<LeaderboardRowDto>();

export const LeaderboardPage = () => {
  const columns = [
    columnHelper.accessor('date', {
      cell: (info) => info.row.index + 1,
      header: '#',
    }),
    columnHelper.accessor('nickname', {
      cell: (info) => info.getValue(),
      header: 'Nickname',
    }),
    columnHelper.accessor('date', {
      cell: (info) => format(new Date(info.getValue()), 'dd MMM yyyy'),
      header: 'Date',
    }),
    columnHelper.accessor('duration', {
      cell: (info) => info.getValue(),
      header: 'Duration',
    }),
    columnHelper.accessor('score', {
      cell: (info) => info.getValue(),
      header: 'Score',
    }),
  ];

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [gameMode, setGameMode] = useState<GameMode>(
    (searchParams.get('mode') as GameMode) ?? GameMode.CLASSIC
  );

  const { data, isLoading } = useDataResource<LeaderboardRowDto[]>('/games/leaderboard', false, {
    mode: gameMode,
  });

  const { data: options } = useDataResource<GameModeOptionCopyDto[]>(
    '/utils/game-modes-copy',
    false
  );

  useEffect(() => {
    document.title = 'MapIQ Leaderboard - Top Players Around the Globe';
  }, []);

  return (
    <Layout
      navbar={
        <Navbar
          buttons={[
            {
              label: 'Hub',
              onClick: () => navigate('/hub'),
              bgColor: 'green.300',
              icon: <Icon h={5} w={5} as={IoMdPin} />,
            },
          ]}
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <VStack py={16} px={[8, 16]}>
          <Box
            py={{ base: '4', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={{ base: 'transparent', sm: 'white' }}
            boxShadow={{ base: 'none', sm: 'md' }}
            borderRadius={{ base: 'none', sm: 'xl' }}
            w="full"
          >
            <Flex justifyContent="space-between" pb={8}>
              <Heading as="h2" size={['lg', 'xl']} fontWeight={900}>
                Top 100 Players
              </Heading>

              <Select
                w={[32, 48, 64]}
                placeholder="Select game mode"
                onChange={(e) => setGameMode(e.currentTarget.value as GameMode)}
                defaultValue={gameMode as string}
              >
                {options?.map((option, index) => (
                  <option key={index} value={option.key}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Show breakpoint="xl">
              {isLoading ? (
                <Stack>
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                  <Skeleton height="30px" />
                </Stack>
              ) : (
                <TableContainer>
                  <Table variant="simple" size="lg" colorScheme="blue">
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th>Nickname</Th>
                        <Th>Date</Th>
                        <Th isNumeric>Duration</Th>
                        <Th isNumeric>Score</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.map((row, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>{row.nickname}</Td>
                          <Td>{format(new Date(row.date), 'dd MMM yyyy')}</Td>
                          <Td isNumeric>{row.duration}</Td>
                          <Td isNumeric>{row.score}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </Show>
            <Hide breakpoint="xl">
              <DataTable columns={columns} data={data || []} title="" />
            </Hide>
          </Box>
        </VStack>
      </Box>
    </Layout>
  );
};
