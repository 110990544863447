import { UserDto } from 'types/api/user.dto';
import { GlobalContextType } from './model';

export type Action = { type: 'set-user'; payload: UserDto } | { type: 'logout' } | { type: 'init' };
export type Reducer = (prevState: GlobalContextType, action: Action) => GlobalContextType;

export const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'set-user':
      return { ...state, user: action.payload, isAuthenticated: true };

    case 'logout':
      return { ...state, user: null, isAuthenticated: false };

    default:
      return state;
  }
};
